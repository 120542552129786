@import '~antd/dist/antd.css';

.iptv-search {
    margin-top: 16px;
    margin-left: 16px;
}

@media screen and (max-width: 1200px) {
    /* xl 1200px */
    .left-search {
        margin-left: 45px;
    }
}

.ant-list-bordered .ant-list-item{
    padding-left: 6px;
    padding-right: 6px;
}

li.ant-list-item{
    padding-top: 6px;
    padding-bottom: 6px;
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: #fff;
}
